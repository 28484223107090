html, body {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

/* ==========================================================================
Base Styles, Bootstrap Modifications & Fonts Import
========================================================================== */

@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);


::-moz-selection {
background: #f2c2c9;
color: #a4003a;
text-shadow: none;
}

::selection {
background: #16a085;
color: #a4003a;
text-shadow: none;
}

/*
* A better looking default horizontal rule
*/

hr {
display: block;
height: 1px;
border: 0;
border-top: 1px solid #ccc;
margin: 1em 0;
padding: 0;
}

.centered {
text-align: center
}

.mt {
margin-top: 80px;
}

.mb {
margin-bottom: 80px;
}

/* ==========================================================================
General styles
========================================================================== */


body {
font-family: 'Montserrat', sans-serif;
font-weight: 400;
font-size: 16px;

-webkit-font-smoothing: antialiased;
-webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
font-weight: 700;
color: #4a4a4a;
}


.navbar-brand {
font-weight: 700;
}

/* ==========================================================================
Wrap Sections
========================================================================== */

.slider {

}

.slide {
	// background: url(http://cdn1.editmysite.com/uploads/1/9/1/5/19152923/background-images/1631019244.jpg) no-repeat center top;
	margin-top: -70px;
	padding-top: 250px !important;
	text-align: center;
	background-attachment: relative;
	background-position: center center;
	min-height: 650px;
	width: 100%;
	-webkit-background-size: 100%;
	-moz-background-size: 100%;
	-o-background-size: 100%;
	background-size: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#headerwrap h1,
.slide h1 {
    color:#ffffff;
    padding-top: 10px;
    padding-bottom: 20px;
    letter-spacing: 4px;
    font-size: 80px;
    font-weight: bold;
    text-transform: uppercase;
}

#headerwrap h4,
.slide h4 {
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;
}

#social {
padding-top: 50px;
padding-bottom: 50px;
// background-color: #f2f2f2;
}

#social i {
font-size: 40px;
color: #4a4a4a
}

#footerwrap {
padding-top: 30px;
padding-bottom: 30px;
background-color: #2f2f2f;
}

#footerwrap p {
color: #f2f2f2;
margin-left: 10px;
}

#workwrap {
background: url(../img/work.jpg) no-repeat center top;
margin-top: -70px;
padding-top: 250px;
text-align:center;
background-attachment: relative;
background-position: center center;
min-height: 650px;
width: 100%;

-webkit-background-size: 100%;
-moz-background-size: 100%;
-o-background-size: 100%;
background-size: 100%;

-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}

#workwrap h1 {
color:#ffffff;
padding-top: 10px;
padding-bottom: 20px;
letter-spacing: 4px;
font-size: 80px;
font-weight: bold;
}

#workwrap h4 {
font-weight: 400;
color: #ffffff
}

#aboutwrap {
background: url(../img/about.jpg) no-repeat center top;
margin-top: -70px;
padding-top: 250px;
text-align:center;
background-attachment: relative;
background-position: center center;
min-height: 550px;
width: 100%;

-webkit-background-size: 100%;
-moz-background-size: 100%;
-o-background-size: 100%;
background-size: 100%;

-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}




/* Gallery */
.gallery {
margin-top: 30px;
}

.gallery img:hover {
opacity: 0.4;
}


/* our code */
.search-wrapper {
    margin: 0 -500%;
    padding: 0 500%;
    // background-color: #ccc;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar {
    background-color: transparent;
    border-bottom: 0;
}

.navbar.navbar-scrolled {
    background-color: rgba(0,0,0,0.5);
}

.navbar.navbar-relative {
    background-color: rgba(0,0,0,0.5);
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-brand {
    color: #fff;
}

.navbar-brand {
    img {
        height: 38px;
        padding-top: 0;
        margin-top: -5px;
    }
}

.navbar ul.dropdown-nav {
    position: absolute;
    left: -99999em;
    z-index: 1000;
    background-color: #fff;
    width: 300px;
    // border-top: 2px solid #414141;
    list-style: none;
    padding: 0;
    border-top: 1px solid #ccc;
}

.navbar li:hover .dropdown-nav {
    left: auto;
}

.navbar .dropdown-nav li a {
    display: block;
    padding: 15px;
    border-left: 4px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.navbar .dropdown-nav li a:hover {
    background-color: #ccc;
}

.navbar li:last-child:hover .dropdown-nav {
    left: -150px;
}

.gallery a {
    display: block;
    background-color: #555;
    color: #fff;
    width: 100%;
    min-height: 200px;
    font-size: 24px;
    padding-top: 80px;
}

.slider {
    // margin-top: 94px;
}

.unslider {
    position: relative;
}

.unslider-arrow {
    display: block;
    width: 32px;
    height: 32px;
    top: 55%;
    right: -50px;
    left: auto;
    margin-top: -16px;
    overflow: hidden;
    background: rgba(0,0,0,.2) no-repeat 50% 50%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAQAAABuQZ3IAAAAi0lEQVR4AU3OISBEQQBAwS0AACS9NxqQgCZpkiYBVddFvWhAAUABAPQCAGC4g/0vTnrBqCfDIZl70J+kMUBPpEwT4FNXxBxz4F1HxHyr4EVTxBLb4EFNxEon4CJSlVNw9AcV9sC16h8osgke1P1ArgXwouVvdQq86ww/GQefusNf7kBviBlxpT8k+gL/Wox4r1d4MwAAAABJRU5ErkJggg==');
    background-size: 7px 11px;
    border-radius: 32px;
    text-indent: -999em;
    opacity: .6;
    transition: opacity .2s;
}
.unslider-arrow.prev {
	left: 20px;
	right: auto;
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.mission {
    text-align: center;
}

.footer-nav {
    list-style: none;

    li {
        display: inline-block;

        a {
            display: inline-block;
            padding: 10px;
        }
    }
}

@media (max-width: 500px) {
	.navbar {
		position: relative !important;
        margin-bottom: 0 !important;
	}
    .search-wrapper {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    .slider {
        margin-top: 0;
    }
    .main {
        margin-top: 0 !important;
    }
}

#search {
    padding: 50px;
    background-color: #CACACA;
}

#about {
    padding: 50px;
    background-color: cyan;
}

#news {
    padding-top: 40px;
}
